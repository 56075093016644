<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { api } from '@src/api'

const isLoaded = ref(false)
const isEnabled = ref(false)
const countNew = ref(0)
const quote = ref('')
const author = ref('')

const loadQuote = () => {
  api.diaries.getDayQuote()
    .then((data) => {
      isLoaded.value = true
      isEnabled.value = data.isEnabled
      countNew.value = data.countNew
      quote.value = data.quote
      author.value = data.author
    })
}

const changeEnabled = () => {
  api.diaries
    .setDayQuote(isEnabled.value)
    .then(() => {
      if (isEnabled.value) {
        loadQuote()
      }
    })
}

onMounted(() => {
  loadQuote()
})
</script>

<template>
  <div
    v-if="isLoaded"
    class="day-quote"
  >
    <div class="day-quote--info">
      <div class="day-quote--info--left">
        <img
          class="day-quote--logo"
          src="../assets/img/logo-icon.png"
          alt="Quote"
        />

        <div class="h4">Цитата дня</div>
      </div>

      <div
        v-show="false && !isEnabled"
        class="day-quote--info--status"
      >
        {{ countNew }}
        {{
          $pluralize(countNew, ['новая', 'новые', 'новых'])
        }}
      </div>

      <div class="day-quote--info--right">
        <input
          v-model="isEnabled"
          type="checkbox"
          id="day-quote"
          @change="changeEnabled"
        />
        <label for="day-quote"></label>
      </div>
    </div>

    <div
      v-if="isEnabled"
      class="day-quote--content"
    >
      <p v-for="line in quote.split('\n')">{{ line }}</p>
    </div>

    <div
      v-if="isEnabled"
      class="day-quote--author"
    >
      {{ author }}
    </div>

    <div
      v-if="!isEnabled"
      class="day-quote--description"
    >
      <div class="emotions-thinking-face"></div>
      <span>включите для вдохновения </span>
      <div class="emotions-smiling-face"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.day-quote {
  margin-bottom: 20px;
  padding: 20px 15px 10px;
  display: flex;
  flex-flow: column nowrap;
  background: #ffffff;
  border: 1px solid #4cdfa2;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 #cad9ef66;

  @media screen and (min-width: $desktop) {
    margin-bottom: 60px;
    padding: 20px;
  }

  &--info {
    margin: 0;
    padding-bottom: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: $desktop) {
      padding-bottom: 18px;
    }

    &--left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 10px;

      @media screen and (min-width: $desktop) {
        gap: 20px;
      }

      .h4 {
        font-size: 17px;
        color: #015bb0;

        @media screen and (min-width: $desktop) {
          font-size: 28px;
        }
      }
    }

    &--status {
      padding: 2px 4px;
      font-size: 12px;
      color: #ffffff;
      font-weight: 500;
      line-height: 1;
      background: linear-gradient(0deg, #0da7f3, #0da7f3),
        linear-gradient(341.5deg, #10bdff -27.59%, #005ac4 234.81%);
      border-radius: 11px;

      @media screen and (min-width: $desktop) {
        padding: 3px 7px;
        font-size: 16px;
      }
    }
  }

  &--logo {
    width: 28px;
    height: 28px;

    @media screen and (min-width: $desktop) {
      width: 40px;
      height: 40px;
    }
  }

  &--content {
    position: relative;
    padding: 3px 24px;
    min-height: 40px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    box-sizing: border-box;

    @media screen and (min-width: $desktop) {
      margin: 0 auto;
      padding: 0 24px;
      width: 100%;
      max-width: 900px;
      font-size: 17px;
      line-height: 1.2;
    }

    p {
      margin-top: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 6px;
      top: 0;
      width: 9px;
      height: 14px;
      background: url(../assets/img/i-quote-left.svg) center center no-repeat;
      background-size: 9px 14px;

      @media screen and (min-width: $desktop) {
        left: 2px;
        width: 16px;
        height: 25px;
        background-size: 16px 25px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: 6px;
      bottom: 0;
      width: 9px;
      height: 14px;
      background: url(../assets/img/i-quote-right.svg) center center no-repeat;
      background-size: 9px 14px;

      @media screen and (min-width: $desktop) {
        right: 2px;
        width: 16px;
        height: 25px;
        background-size: 16px 25px;
      }
    }
  }

  &--author {
    margin-top: 13px;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    text-align: right;
    color: #015bb0;

    @media screen and (min-width: $desktop) {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      font-size: 15px;
      font-weight: 500;
      text-align: right;
    }
  }

  &--description {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 500;

    @media screen and (min-width: $desktop) {
      font-size: 17px;
    }

    > div {
      --size: 20;
    }
  }
}

input {
  display: none;
}

input + label {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
  background: linear-gradient(180deg, #939ba3 0%, #9ba4ad 100%);
  border-radius: 12px;
  opacity: 0.8;
  box-shadow: inset 0 3.2px 3.2px 0 #00000026;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 50%;
  }
}

input:checked + label {
  background: linear-gradient(0deg, #0da7f3, #0da7f3),
    linear-gradient(341.5deg, #10bdff -27.59%, #005ac4 234.81%);

  &:after {
    top: 3px;
    left: auto;
    right: 3px;
  }
}
</style>
