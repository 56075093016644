import { request } from '@src/axios.ts'

type UsersSearch = {
  total: number
  page: number
  result: UserSearch[]
}

type UserSearch = {
  id: number
  fullName: string
  avatar: string | null
  birthDate: string | null
  countDiaries: number
}

type UsersUserMessage = {
  email: string
  name: string
  message: string
  type?: string
  userId: number
}

type UsersProfile = {
  id: number
  login: string
  email: string
  role: 'admin' | 'user' | 'child'
  fullName: string | null
  avatar: string | null
  birthDate: string | null
  isShowDiary: boolean
  isShowPhotoMain: boolean
  isMotivationEnabled: boolean
  createdAt: string
}

type UsersProfileUpdate = {
  fullName: string
  birthDate: string | null
  avatar: string
}

type ParentProfile = {
  id: number
  fullName: string
  avatar: string | null
  birthDate: string | null
  email: string
  role: string
  isShowPhotoMain: boolean
  createdAt: string
  parentId: number
}

type UsersLoginPassword = {
  email: string
  password: string
}

type UsersCheckLoginPassword = {
  token: string
  code: string
}

export default class UsersResource {
  private resourceUrl = '/users'

  search(search: string, page = 1, limit = 10) {
    return request<UsersSearch>({
      method: 'get',
      url: this.resourceUrl + '/search',
      params: {
        search,
        page,
        limit,
      },
    })
  }

  getSearchUser(id: number) {
    return request<UserSearch>({
      method: 'get',
      url: this.resourceUrl + `/search-user/${id}`,
    })
  }

  sendMessageToUser(data: UsersUserMessage) {
    return request<'true'>({
      method: 'post',
      url: this.resourceUrl + '/send-email',
      data,
    })
  }

  profile() {
    return request<UsersProfile>({
      method: 'get',
      url: this.resourceUrl + '/profile',
    })
  }

  profileUpdate(data: Partial<UsersProfileUpdate>) {
    return request<UsersProfile>({
      method: 'put',
      url: this.resourceUrl + '/profile',
      data,
    })
  }

  parent() {
    return request<ParentProfile>({
      method: 'get',
      url: this.resourceUrl + '/profile/parent',
    })
  }

  credentialChild() {
    return request<{
      login: string
      password: string
    }>({
      method: 'get',
      url: this.resourceUrl + '/credential-child',
    })
  }

  loginPassword(data: Partial<UsersLoginPassword>) {
    return request<{
      token: string
      message: string
    }>({
      method: 'put',
      url: this.resourceUrl + '/login-or-password',
      data,
    })
  }

  checkUpdateLoginPassword(data: UsersCheckLoginPassword) {
    return request<UsersProfile>({
      method: 'post',
      url: this.resourceUrl + '/check-update-login-or-password',
      data,
    })
  }

  updateShowDiary(isShow: boolean) {
    return request<boolean>({
      method: 'put',
      url: this.resourceUrl + '/show-diary',
      data: {
        isShow,
      },
    })
  }

  updateShowPhoto(isShow: boolean) {
    return request<boolean>({
      method: 'put',
      url: this.resourceUrl + '/show-photo',
      data: {
        isShow,
      },
    })
  }

  updateMotivationEnabled(isEnabled: boolean) {
    return request<boolean>({
      method: 'put',
      url: this.resourceUrl + '/motivation-email',
      data: {
        isEnabled,
      },
    })
  }
}
