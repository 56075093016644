<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { api } from '@src/api'
import loadSecImage from '@src/loadSecImage.ts'
import 'vue3-carousel/dist/carousel.css'

type SlideItem = {
  src: string
}

const slides = ref<SlideItem[]>([])

onMounted(() => {
  slides.value = []

  api.files.carouselFiles().then((items) => {
    slides.value = items.map((item) => ({
      src: loadSecImage(item, 'full'),
    }))
  })
})
</script>

<template>
  <Carousel :wrap-around="true">
    <Slide
      v-for="(slide, index) in slides"
      :key="index"
    >
      <div class="img-wrapper">
        <img
          :src="slide.src"
          alt=""
        />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<style lang="scss" scoped>
.carousel {
  padding: 10px 0;
  text-align: center;

  .img-wrapper {
    padding: 6px 6px 22px;
    width: 192px;
    height: 165px;
    border: 1.3px solid #010101;
    border-radius: 11px;
    background: #ffffff;
    box-sizing: border-box;

    @media screen and (min-width: $desktop) {
      padding: 12px 12px 44px;
      width: 384px;
      height: 330px;
      border: 2.6px solid #010101;
    }
  }

  img {
    width: 100%;
    border: .64px solid #010101;
    border-radius: 7.43px;
    object-fit: cover;

    @media screen and (min-width: $desktop) {
      border: 1.28px solid #010101;
    }
  }
}
</style>
