<script setup lang="ts">
import { inject, onMounted, ref, watch } from 'vue'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import loadSecImage from '@src/loadSecImage.ts'
import FormText from '@src/components/form/FormText.vue'
import FormArea from '@src/components/form/FormArea.vue'
import Popup from '@src/components/Popup.vue'
import imgUpload from '@src/assets/img/img-upload.gif'
import type { DiaryTypeFile } from '@src/api/diaries.ts'

const props = defineProps<{
  id: number
  theme: string
  text: string
  draft: boolean
  files: DiaryTypeFile[]
}>()

const emit = defineEmits<{
  updateDiaries: [number, boolean]
  updateOnePost: [number, string, string, DiaryTypeFile[] | undefined]
}>()

let axiosController: AbortController

const uploadTypes = {
  images: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  videos: ['video/mp4', 'video/quicktime', 'video/x-quicktime', 'video/avi'],
}

const eventBus = inject(LocalEventBus)
const validator = new Validator()
const uploadEl = ref<HTMLInputElement | null>(null)
const diaryId = ref(0)
const diaryTheme = ref('')
const diaryText = ref('')
const diaryDraft = ref(false)
const diaryFiles = ref<DiaryTypeFile[]>([])
const isLoading = ref(false)
const showUpload = ref(false)
const uploadSize = ref(0)
const uploadSizeTotal = ref(0)

const availableUploadTypes = () =>
  [...uploadTypes.images, ...uploadTypes.videos].join(',')

const handleChange = () => {}

const handleSave = (isDraft: boolean) => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result) {
        if (diaryId.value === 0) {
          api.diaries
            .create({
              theme: diaryTheme.value,
              text: diaryText.value,
              isDraft: isDraft,
              fileIds: diaryFiles.value.map((item) => item.uuid),
            })
            .then((data) => {
              emit('updateDiaries', data.id, data.isDraft ?? false)
            })
            .finally(() => {
              isLoading.value = false
            })
        } else {
          api.diaries
            .update(diaryId.value, {
              theme: diaryTheme.value,
              text: diaryText.value,
              isDraft: isDraft,
              fileIds: diaryFiles.value.map((item) => item.uuid),
            })
            .then((data) => {
              if (diaryDraft.value !== isDraft) {
                emit('updateDiaries', data.id, data.isDraft ?? false)
              } else {
                emit(
                  'updateOnePost',
                  data.id,
                  data.theme,
                  data.text,
                  data.files,
                )
              }
            })
            .finally(() => {
              isLoading.value = false
            })
        }
      } else {
        isLoading.value = false
      }
    })
  }
}

const uploadFile = async (event: Event) => {
  const target = event.target as HTMLInputElement

  if (target.files && target.files.length > 0) {
    showUpload.value = true

    let alreadyUploaded = 0
    uploadSize.value = 0
    uploadSizeTotal.value = Array.from(target.files)
      .map((file) => file.size)
      .reduce((total, item) => total + item)

    for (const file of target.files) {
      if (!showUpload.value) {
        break
      }

      axiosController = new AbortController()

      try {
        if (uploadTypes.images.includes(file.type)) {
          const { data } = await api.files.uploadImage(
            'DIARY',
            file,
            axiosController.signal,
            (progressEvent) => {
              uploadSize.value = alreadyUploaded + progressEvent.loaded
            },
          )

          diaryFiles.value.push({
            fileType: 'image',
            uuid: data,
          })
          alreadyUploaded += file.size
        }

        if (uploadTypes.videos.includes(file.type)) {
          const { data } = await api.files.uploadVideo(
            file,
            axiosController.signal,
            (progressEvent) => {
              uploadSize.value = alreadyUploaded + progressEvent.loaded
            },
          )

          diaryFiles.value.push({
            fileType: 'video',
            uuid: data,
          })
          alreadyUploaded += file.size
        }
      } catch (err) {}
    }

    handleChange()
    showUpload.value = false
  }
}

const removeFile = (index: number) => {
  diaryFiles.value.splice(index, 1)
}

eventBus?.on('editor', (action) => {
  if (action === 'upload') {
    uploadEl.value?.click()
  }
})

onMounted(() => {
  diaryId.value = props.id
  diaryTheme.value = props.theme
  diaryText.value = props.text
  diaryDraft.value = props.draft
  diaryFiles.value = props.files.map((item) => Object.assign({}, item))
})

watch(
  () => diaryTheme.value + diaryText.value,
  () => handleChange(),
)

const formatFilesize = (filesize: number): string => {
  const units = ['B', 'kB', 'MB', 'GB']
  let size = filesize
  let unit = 0

  while (size >= 100) {
    size /= 1024
    unit++
  }

  return `${size.toFixed(size < 10 ? 1 : 0)} ${units[unit]}`
}
</script>

<template>
  <div class="editor">
    <form ref="form">
      <FormText
        v-model="diaryTheme"
        :ref="(el: typeof FormText) => validator.addItem('theme', el)"
        id="theme"
        type="text"
        :rules="Yup.string().required()"
        placeholder="Тема"
      />
      <FormArea
        v-model="diaryText"
        :ref="(el: typeof FormArea) => validator.addItem('text', el)"
        id="text"
        :rules="Yup.string().required()"
      />

      <div class="editor--files">
        <input
          ref="uploadEl"
          type="file"
          multiple
          :accept="availableUploadTypes()"
          @change="uploadFile"
        />

        <div
          v-if="diaryFiles && diaryFiles.length > 0"
          class="editor--files--list"
        >
          <div
            v-for="(file, index) in diaryFiles"
            :key="file.uuid"
            class="editor--files--item"
          >
            <img
              :src="loadSecImage(file.uuid, 'thumbnail')"
              alt=""
            />
            <div
              class="icon-trash"
              @click="removeFile(index)"
            ></div>
          </div>
        </div>

        <div
          v-else
          class="editor--files--text"
        >
          <span class="emotions-camera-with-flash"></span>
          И, конечно же, старайтесь добавлять фото. Все получится!
          <span class="emotions-fire"></span>
        </div>
      </div>
    </form>

    <div class="actions">
      <div
        v-if="diaryDraft"
        class="btn"
        :class="{ loading: isLoading }"
        @click="handleSave(true)"
      >
        В черновики
      </div>

      <div
        class="btn primary"
        :class="{ loading: isLoading }"
        @click="handleSave(false)"
      >
        Сохранить
      </div>
    </div>
  </div>

  <Popup
    v-model="showUpload"
    :image="imgUpload"
    class="upload-popup"
    @after-close="() => axiosController.abort()"
  >
    <h3 class="h1 blue only-desktop">
      {{ formatFilesize(uploadSize) }} / {{ formatFilesize(uploadSizeTotal) }}
    </h3>
    <h3 class="h2 blue only-mobile">
      {{ formatFilesize(uploadSize) }} / {{ formatFilesize(uploadSizeTotal) }}
    </h3>

    <div class="content">Любовь закачивается. Потерпите</div>

    <div
      class="btn primary"
      @click="showUpload = false"
    >
      Отмена
    </div>
  </Popup>
</template>

<style scoped lang="scss">
.editor {
  padding-top: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100dvh - 120px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 160px;
    align-items: center;
    min-height: 0;
  }

  :deep(.field-wrapper) {
    @media screen and (min-width: $desktop) {
      width: 1000px;
    }
  }

  :deep(textarea) {
    min-height: 260px;
    max-height: 260px;
  }

  &--files {
    margin: 30px -20px 0;
    padding: 0 20px 10px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    overflow-x: auto;

    @media screen and (min-width: $desktop) {
      margin: 60px 0 0;
      padding: 0 0 10px;
      width: 1000px;
      font-size: 24px;
      line-height: 1.4;
    }

    &--list {
      display: flex;
      flex-flow: row nowrap;
    }

    &--item {
      flex: 0 0 90px;
      position: relative;
      padding-right: 20px;
      box-shadow: 0 7.43px 21.23px 0 #00000008;

      @media screen and (min-width: $desktop) {
        flex-basis: 150px;
      }

      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 20px;

        @media screen and (min-width: $desktop) {
          width: 150px;
          height: 150px;
          border-radius: 40px;
        }
      }

      .icon-trash {
        position: absolute;
        top: 5px;
        right: 25px;
        padding: 2px 4px;
        font-size: 16px;
        color: #222222;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        cursor: pointer;

        @media screen and (min-width: $desktop) {
          top: 10px;
          right: 30px;
          padding: 0 6px;
          font-size: 32px;
        }
      }
    }

    &--text {
      @media screen and (min-width: $desktop) {
        margin: 0 auto;
        width: 570px;
        text-align: center;
      }
    }

    input {
      display: none;
    }

    span {
      --size: 18;

      @media screen and (min-width: $desktop) {
        --size: 32;
      }
    }
  }
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;

  @media screen and (min-width: $desktop) {
    margin: 50px auto 0;
    width: 335px;
  }
}

.upload-popup {
  :deep(.popup--icon) {
    @media screen and (min-width: $desktop) {
      margin-left: 60px;
      width: 400px;
      height: 400px;
    }
  }
}

.popup--content {
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;

    &.blue {
      color: #0da7f3;
    }

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  h5 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;
    color: #0da7f3;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    > div {
      position: relative;
      top: 3px;
      display: inline-block;
      --size: 20;
    }
  }

  .btn {
    margin-top: 40px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }
}
</style>