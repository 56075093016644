<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import { useWindowStore } from '@src/store/window'
import { api } from '@src/api'
import Popup from '@src/components/Popup.vue'
import { TariffType } from '@src/api/tariffs.ts'
import 'vue3-carousel/dist/carousel.css'
import imgInfo from '@src/assets/img/img-info.gif'

const windowStore = useWindowStore()

const props = defineProps<{
  canChoose?: boolean
}>()
const emit = defineEmits<{
  choose: [number]
}>()

const showPopup = ref(false)
const popupIndex = ref(0)
const chooseIndex = ref<number | null>(null)
const plans = ref<TariffType[]>([])

const chooseItem = (index: number) => {
  if (props.canChoose ?? false) {
    chooseIndex.value = index
    emit('choose', index)
  }
}

const openPopup = (index: number) => {
  popupIndex.value = index < 0 || index >= plans.value.length ? 0 : index
  showPopup.value = true
}

const carouselInit = () => {
  if (props.canChoose ?? false) {
    chooseIndex.value = 0
    emit('choose', 0)
  }
}

const carouselSlide = (
  data: Required<{
    currentSlideIndex: number
    prevSlideIndex: number
    slidesCount: number
  }>,
) => {
  if (props.canChoose ?? false) {
    chooseIndex.value = data.currentSlideIndex
    emit('choose', data.currentSlideIndex)
  }
}

onMounted(() => {
  api.tariffs.list().then((data) => {
    plans.value = data.filter((item) => item.isEnabled)
  })
})
</script>

<template>
  <img
    v-if="plans.length === 0"
    src="../assets/img/loading.svg"
    class="loading"
    alt="Loading"
  />

  <Carousel
    v-if="windowStore.isMobile"
    :items-to-show="1.2"
    :wrap-around="true"
    @init="carouselInit"
    @slide-end="carouselSlide"
  >
    <Slide
      v-for="(plan, index) in plans"
      :key="index"
    >
      <div class="plan">
        <div class="plan--top">
          <div
            class="plan--icon"
            :class="plan.emoji"
          ></div>
          <div class="plan--name">
            <div class="plan--title">{{ plan.name }}</div>
            <div
              class="plan--subtitle"
              @click="openPopup(index)"
            >
              {{ plan.duration }}
            </div>
          </div>
        </div>
        <div class="plan--prices">
          <div
            v-if="plan.price !== '0'"
            class="plan--old-price"
          >
            {{ plan.price }} ₽
          </div>

          <div class="plan--price">
            {{
              plan.originalPrice !== '0'
                ? plan.originalPrice + ' ₽'
                : 'любая сумма'
            }}
          </div>
        </div>
      </div>
    </Slide>
  </Carousel>

  <div
    v-if="windowStore.isDesktop"
    class="plans-wrapper"
  >
    <div
      v-for="(plan, index) in plans"
      :key="index"
      :class="{ plan: true, choose: chooseIndex === index }"
      @click="chooseItem(index)"
    >
      <div class="plan--top">
        <div
          class="plan--icon"
          :class="plan.emoji"
        ></div>
        <div class="plan--name">
          <div class="plan--title">{{ plan.name }}</div>
          <div
            class="plan--subtitle"
            @click="openPopup(index)"
          >
            {{ plan.duration }}
          </div>
        </div>
      </div>
      <div class="plan--prices">
        <div
          v-if="plan.price !== '0'"
          class="plan--old-price"
        >
          {{ plan.price }} ₽
        </div>

        <div class="plan--price">
          {{
            plan.originalPrice !== '0'
              ? plan.originalPrice + ' ₽'
              : 'любая сумма'
          }}
        </div>
      </div>
    </div>
  </div>

  <Popup
    v-model="showPopup"
    :image="imgInfo"
  >
    <div class="plan--top">
      <div
        class="plan--icon"
        :class="plans[popupIndex].emoji"
      ></div>
      <div class="plan--name">
        <div class="plan--title">{{ plans[popupIndex].name }}</div>
        <div class="plan--subtitle">{{ plans[popupIndex].duration }}</div>
      </div>
    </div>
    <div class="plan--about">
      <p
        v-for="(line, index) in plans[popupIndex].description.split('\n')"
        :key="index"
      >
        {{ line }}
      </p>
    </div>
    <div class="plan--prices">
      <div
        v-if="plans[popupIndex].price !== '0'"
        class="plan--old-price"
      >
        {{ plans[popupIndex].price }} ₽
      </div>

      <div class="plan--price">
        {{
          plans[popupIndex].originalPrice !== '0'
            ? plans[popupIndex].originalPrice + ' ₽'
            : 'любая сумма'
        }}
      </div>
    </div>
  </Popup>
</template>

<style scoped lang="scss">
img.loading {
  margin: 20px auto;
  display: block;

  @media screen and (min-width: $desktop) {
    width: 90px;
  }
}

.plans-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.plan {
  margin: 10px 0 25px;
  padding: 15px 20px;
  display: flex;
  width: 100%;
  height: 110px;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: normal;
  text-align: left;
  background: #ffffff;
  border: 1px solid #e7f4f9;
  border-radius: 10px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 0;

    &:hover,
    &.choose {
      transform: scale(1.08);
      box-shadow: 0 10px 25px 0 #cad9efcc;
    }

    &:hover {
      z-index: 3;
    }
  }

  &.active,
  .carousel__slide--active & {
    padding: 17px 20px;
    height: 120px;
    box-shadow: 0 10px 25px 0 #cad9efcc;
  }

  &--top {
    display: flex;
    height: 40px;
    flex-flow: row nowrap;
  }

  &--icon {
    align-self: center;
    margin-right: 15px;
    font-size: 30px;
    --size: 30;
  }

  &--name {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  &--title {
    font-size: 16px;
  }

  &--subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #146fda;
    cursor: pointer;
  }

  &--about {
    font-size: 14px;
  }

  &--prices {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &--price {
    font-size: 22px;
    font-weight: 600;
    color: #27ae60;
  }

  &--old-price {
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
  }
}

.carousel {
  margin: 0 -20px;

  &__slide {
    padding: 0 10px;
  }
}

.popup--content {
  .plan {
    &--top {
      padding: 10px 20px;
      height: 40px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 20px 40px 0 #cad9ef66;
    }

    &--about {
      margin: 20px 0;
    }

    @media screen and (min-width: $desktop) {
      &--top {
        padding: 20px;
        height: 76px;
      }

      &--icon {
        font-size: 56px;
        --size: 56;
      }

      &--title {
        font-size: 28px;
      }

      &--subtitle {
        font-size: 20px;
      }

      &--about {
        margin: 40px 0;
        font-size: 15px;
      }

      &--price {
        font-size: 32px;
      }

      &--old-price {
        font-size: 20px;
      }
    }
  }
}
</style>
