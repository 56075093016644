<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { useWindowStore } from '@src/store/window.ts'

const props = defineProps<{
  url: string
}>()
const emit = defineEmits<{
  finish: []
}>()

const player = ref<WaveSurfer>()
const waveform = ref<HTMLElement>()
const canPlay = ref(false)
const isPlayed = ref(false)
const outputDuration = ref<string | null>(null)

const handleBtn = () => {
  player.value!.playPause()
}

onMounted(() => {
  player.value = WaveSurfer.create({
    container: waveform.value!,
    backend: 'WebAudio',
    autoplay: false,
    height: useWindowStore().isMobile ? 25 : 38,
    barGap: 4,
    barRadius: 2,
    barWidth: 4,
    cursorWidth: 0,
    waveColor: '#cdd0d0',
    progressColor: ['#005ac4', '#10bdff', '#005ac4', '#10bdff'],
    url: props.url,
  })

  player.value.on('decode', (duration) => {
    outputDuration.value =
      Math.floor(duration / 60) +
      ':' +
      Math.floor(duration % 60)
        .toString()
        .padStart(2, '0')
    canPlay.value = true
  })
  player.value.on('play', () => {
    isPlayed.value = true
  })
  player.value.on('pause', () => {
    isPlayed.value = false
  })
  player.value.on('finish', () => {
    isPlayed.value = false
    emit('finish')
  })
})

onUnmounted(() => {
  player.value?.stop()
})

const play = () => {
  player.value?.play()
}

defineExpose({
  play,
})
</script>

<template>
  <div class="audio-container">
    <div
      class="wave"
      :class="{ played: isPlayed }"
    >
      <div class="wave">
        <div
          v-show="canPlay"
          class="wave audio-container--btn"
          @click="handleBtn"
        ></div>
      </div>
    </div>

    <div
      ref="waveform"
      class="audio-container--waveform"
    ></div>

    <div
      v-if="outputDuration !== null"
      class="audio-container--duration"
    >
      {{ outputDuration }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.audio-container {
  position: relative;
  margin: 0 25px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (min-width: $desktop) {
    margin: 0 45px;
    gap: 16px;
  }

  &--btn {
    cursor: pointer;
  }

  &--waveform {
    flex-grow: 1;

    @media screen and (min-width: $desktop) {
      max-width: 300px;
    }
  }

  &--duration {
    position: absolute;
    bottom: 3px;
    left: 72px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    opacity: 0.8;

    @media screen and (min-width: $desktop) {
      bottom: 5px;
      left: 124px;
      font-size: 16px;
    }
  }
}

.wave {
  padding: 3px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding: 5px;
    width: 108px;
    height: 108px;
  }

  &.played {
    background: rgba(13, 166, 242, 0.045);

    .wave {
      background: rgba(13, 166, 242, 0.21);

      .wave {
        background: rgba(13, 166, 242) url(../assets/img/i-pause.svg) center
          center no-repeat;

        @media screen and (min-width: $desktop) {
          background-size: 25px;
        }
      }
    }
  }

  .wave {
    padding: 6px;
    width: 100%;
    height: 100%;

    @media screen and (min-width: $desktop) {
      padding: 11px;
    }

    .wave {
      background: rgba(13, 166, 242) url(../assets/img/i-play.svg) 55% center
        no-repeat;

      @media screen and (min-width: $desktop) {
        background-size: 25px;
      }
    }
  }
}
</style>
