<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import Validator, { Yup } from '@src/validator.ts'
import FormText from '@src/components/form/FormText.vue'
import FormArea from '@src/components/form/FormArea.vue'
import Popup from '@src/components/Popup.vue'
import imgInfo from '@src/assets/img/img-info.gif'

const authStore = useAuthStore()

const validator = new Validator()
const isLoading = ref(false)
const email = ref('')
const subject = ref('')
const message = ref('')
const showPopup = ref(false)

const sendMessage = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result) {
        api.help
          .send({
            email: email.value,
            subject: subject.value,
            message: message.value,
          })
          .then(() => {
            email.value = authStore.user?.email ?? ''
            subject.value = ''
            message.value = ''
            showPopup.value = true
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}

onMounted(() => {
  email.value = authStore.user?.email ?? ''
})
</script>

<template>
  <main>
    <div class="form-block">
      <form ref="form">
        <h2>Связаться с нами</h2>

        <FormText
          v-model="email"
          :ref="(el: typeof FormText) => validator.addItem('email', el)"
          id="email"
          type="email"
          :rules="Yup.string().email().required()"
          label="Ваш Email:"
        />
        <FormText
          v-model="subject"
          :ref="(el: typeof FormText) => validator.addItem('subject', el)"
          id="subject"
          type="text"
          :rules="Yup.string().required()"
          label="Тема сообщения:"
        />
        <FormArea
          v-model="message"
          :ref="(el: typeof FormArea) => validator.addItem('message', el)"
          id="message"
          :rules="Yup.string().required()"
          label="Сообщение:"
        />
      </form>

      <div class="actions">
        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="sendMessage"
        >
          Отправить
        </div>
      </div>
    </div>
    <div class="img-block only-desktop">
      <img
        src="../assets/img/img-contact.gif"
        alt="Contact"
      />
    </div>
  </main>

  <Popup
    v-model="showPopup"
    :image="imgInfo"
  >
    <h3 class="h1 only-desktop">Отправлено</h3>
    <h3 class="h2 only-mobile">Отправлено</h3>

    <div class="content">
      <p>📮 Ваше сообщение успешно отправлено!</p>
      <p>
        В ближайшее время наш специалист свяжется с Вами по электронной почте
      </p>
    </div>

    <div
      class="btn primary"
      @click="showPopup = false"
    >
      Ок
    </div>
  </Popup>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 156px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  form {
    img {
      margin: 0 auto 30px;
      display: block;
      width: 160px;
      max-width: 100%;
    }

    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;
  }
}

.img-block {
  img {
    margin: 0 auto;
    display: block;
    width: 500px;
    height: 500px;
    max-width: 100%;
  }
}

.popup--content {
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .content {
    font-size: 14px;
  }

  .btn {
    margin-top: 40px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }
}
</style>
